import React, { useCallback, useEffect, useState } from "react";
import { FiUsers } from "react-icons/fi";
import api from "../../services/api";
import Layout from "../../template/components/Layout";
import MainContainer from "../../template/components/MainContainer";
import Pagination from "../../template/components/Pagination";
import Table from "../../template/components/Table";
import { dialogBox } from "../../template/utils/dialogBox";
import { parseJsonToTableDataList } from "../../template/utils/parser";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import {
  FamiliaReadInterface,
  MunicipioInterface,
  PontoInterface,
} from "../../template/utils/types";
import DetalharModal from "./components/DetalharModal";
import TrocarModal from "./components/TrocarModal";
import {
  Container,
  CustomIconDesvincular,
  CustomIconTrocarPonto,
  CustomSpinner2,
} from "./styles";
import AlertModal from "./components/alertModal";
import { useForm } from "react-hook-form";
import { CustomInput } from "../../template/styles/styles";
import { pdf } from "@react-pdf/renderer";
import FileSaver from "file-saver";
import DocumentPDF from "./components/DocumentPDF";
import CartaoPDF from "./components/CartaoPDF";
import PdfModal from "./components/PdfModal";
import Button from "../../template/components/Button";
import { MdPictureAsPdf } from "react-icons/md";
import { FaFileCsv } from "react-icons/fa";
import { json2csv } from "json-2-csv";
import SearchBar from "../../template/components/SearchBar";
import { reduceEachTrailingCommentRange } from "typescript";

const FamiliasVinculadas = () => {
  const { register, handleSubmit, clearErrors, setValue, errors } =
    useForm<any>();

  const [familia, setFamilia] = useState<any[]>([]);
  const [modalDetalhar, setModalDetalhar] = useState(false);
  const [total, setTotal] = useState<number>(0);
  const [municipioSelected, setMunicipioSelected] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);
  const [searchedValue, setSearchedValue] = useState<string>("");
  const [isFamiliasCleared, setIsFamiliasCleared] = useState(false);
  const [familiaEditar, setFamiliaEditar] =
    useState<FamiliaReadInterface>(Object);
  const [municipios, setMunicipios] = useState<MunicipioInterface[]>([]);
  const [pontos, setPontos] = useState<any[]>([]);
  const [pontosData, setPontosData] = useState<any[]>([]);
  const [selectedPonto, setSelectedPonto] = useState<any>("0");
  const [selectedPontoCartao, setSelectedPontoCartao] = useState<any>("0");
  const [selectMunicipioOption, setSelectMunicipioOption] = useState<any>();
  const [selectMunicipioName, setSelectMunicipioName] = useState<string>("");
  const [modalTrocar, setModalTrocar] = useState<boolean>(false);
  const [totalPontos, setTotalPontos] = useState<number>(0);
  const [selectedPontoTroca, setSelectedPontoTroca] = useState<any>();
  const [selectedFamilias, setSelectedFamilias] = useState<any>([]);
  const [selectedTodasFamilias, setSelectedTodasFamilias] = useState<any>([]);
  const [familiaId, setFamiliaId] = useState<number>();
  const [pontosFiltrado, setPontosFiltrado] = useState<any>([]);
  const [modalAlert, setModalAlert] = useState<boolean>(false);
  const [alertaTipo, setAlertaTipo] = useState<any>();
  const [situacao, setSituacao] = useState<number>(0);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [isPontosLoading, setIsPontosLoading] = useState<boolean>(false);
  const [startPage, setStartPage] = useState<number>(0);
  const [endPage, setEndPage] = useState<number>(0);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [modalPdf, setModalPdf] = useState<boolean>(false);
  const [isVinculandoTodos, setIsVinculandoTodos] = useState<boolean>(false);
  const [listaFamilias, setListaFamilias] = useState<any>([]);
  const [selectedFamiliasFilter, setSelectedFamiliasFilter] =
    useState<string>("");
  const [codsFamilias, setCodsFamilias] = useState<any>([]);
  const [listaFamiliasCartao, setListaFamiliasCartao] = useState<any>([]);
  const [csvCheck, setCsvCheck] = useState<boolean>(false);
  const [cartaoCheck, setCartaoCheck] = useState<boolean>(false);
  const [totalMunicipio, setTotalMunicipio] = useState<number>(0);
  const [fileType, setFileType] = useState<string>("");
  const itemsPerPage = 10;

  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );

  // Cabeçalho da tabela
  const fields = [
    "Responsável",
    "Código Familiar",
    "Situação",
    "Renda per capita",
    "Ação",
  ];

  useEffect(() => {
    setCodsFamilias([]);
    (async function () {
      try {
        const municipiosRes = api.get("municipios/");
        const situacoesRes = api.get("situacoes/");
        const [municipioRes, situacaoRes] = await Promise.all([
          municipiosRes,
          situacoesRes,
        ]);
        setMunicipios(municipioRes.data);
        const situacaoId = situacaoRes.data.find(
          (obj: { situacao: string }) => obj.situacao === "EM ESPERA"
        ).id;
        setSituacao(situacaoId);
      } catch (err) {
        notify("error", "Ocorreu um erro no servidor");
      }
    })();
  }, []);

  useEffect(() => {
    setCodsFamilias([]);
    loadPontos();
    clearErrors();
  }, [municipioSelected]);

  const loadPontos = async () => {
    setIsPontosLoading(true);
    const { data } = await api.get(
      `pontos/?municipio=${municipioSelected}&ordering=nome`
    );

    const { count, results } = data;

    setPontos(
      parseJsonToTableDataList(parsePontos(results), [
        "nome",
        "entidade",
        "vagas",
      ])
    );
    let qtdFamilias = 0;
    for (let i = 0; i < results.length; i++) {
      qtdFamilias += results[i].familias_vinculadas;
    }
    setTotalMunicipio(qtdFamilias);
    setPontosData(parsePontos(results));
    setTotalPontos(count);
    setIsPontosLoading(false);
  };

  const loadFamilias = async () => {
    try {
      if (municipioSelected == 0) return;
      const { data } = await api.get(
        `familias/?limit=${itemsPerPage}&offset=${offset}&municipio=${municipioSelected}
        &ponto=${
          selectedPonto == 0 ? "" : selectedPonto
        }&search=${searchedValue}&vinculadas=true&ordering=responsavel`
      );
      const { results, count } = data;
      if (results.length === 0) {
        notify("warning", "Nenhuma família encontrada");
        setFamilia([]);
        // clearFamilias();
        setIsSearching(true);
        return;
      }
      // clearFamilias();
      setFamilia(
        parseJsonToTableDataList(parseFamilias(results), [
          "responsavel",
          "cod_familia",
          "situacao",
          "renda_percapita",
        ])
      );
      setTotal(count);
      // clearFamilias();
      setPontosFiltrado(pontos.filter((ponto) => ponto.id !== selectedPonto));
      setIsSearching(false);
    } catch (error) {
      setIsSearching(false);
      notify("error", "Erro no servidor");
    }
  };

  useEffect(() => {
    setIsSearching(false);
  }, [municipioSelected, selectedPonto]);

  const changeSelected = (obj: any) => {
    if (selectedFamilias.find((element: any) => element === obj.id)) {
      setCodsFamilias(
        codsFamilias.filter((element: any) => element !== obj.cod_familia)
      );
      setSelectedFamilias(
        selectedFamilias.filter((element: any) => element !== obj.id)
      );
    } else {
      setSelectedFamilias([...selectedFamilias, obj.id]);
      setCodsFamilias([...codsFamilias, obj.cod_familia]);
    }
  };

  const removeAll = () => {
    setSelectedFamilias([]);
    setCsvCheck(false);
    setCartaoCheck(false);
  };

  const clearFamilias = () => {
    setIsFamiliasCleared(true);
    setSelectedFamilias([]);
    setCodsFamilias([]);
    setCsvCheck(false);
    setCartaoCheck(false);
  };
  const resetClear = () => {
    setIsFamiliasCleared(false);
  };

  // Parse Gestores
  function parseFamilias(familias: any[]) {
    let tmp = [];
    for (var i = 0; i < familias.length; i++) {
      const object = {
        id: familias[i].id,
        responsavel: familias[i].responsavel.nome,
        cod_familia: familias[i].cod_familia,
        situacao: familias[i].situacao,
        renda_percapita: familias[i].renda_percapita.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        }),
      };
      tmp.push(object);
    }
    return tmp;
  }

  function parsePontos(pontos: any[]) {
    let tmp = [];
    for (var i = 0; i < pontos?.length; i++) {
      let calcVagas =
        pontos[i].cota_leite_bovino +
        pontos[i].cota_leite_caprino -
        pontos[i].familias_vinculadas;
      const object = {
        id: pontos[i].id,
        nome: pontos[i].nome,
        familias: [],
        entidade: pontos[i].entidade.razao_social,
        vagas: calcVagas >= 0 ? calcVagas : 0,
      };
      tmp.push(object);
    }
    return tmp;
  }

  function indicesToString(array: string[]): string {
    return array.join(",");
  }

  useEffect(() => {
    var string = indicesToString(codsFamilias);
    setSelectedFamiliasFilter(string);
  }, [selectedFamilias]);

  const onSubmit = (data: any) => {
    setIsSearching(true);
    setSelectedPontoCartao(data.pontoSelect);
    if (searchedValue) {
      setSearchedValue("");
    } else {
      // Se página for igual a 1, apenas executar a chamada de loadfamilias sem resetar paginação para a primeira página
      if (page === 1) {
        loadFamilias();
      } else {
        first();
        loadFamilias();
      }
    }
  };

  const getListaFamilias = async (startPage: number, endPage: number) => {
    if (municipioSelected > 0) {
      const start = Number(startPage);
      const end = Number(endPage);
      if (
        start > Math.ceil(total / itemsPerPage) ||
        end > Math.ceil(total / itemsPerPage)
      ) {
        notify(
          "error",
          "Um dos valores informados é maior que o valor final da paginação da lista"
        );
        return;
      }

      setStartPage(startPage);
      setEndPage(endPage);

      const calcEndPage = end - start;

      setDownloading(true);

      try {
        const {
          data: { results },
        } = await api.get(
          `familias/?limit=${
            calcEndPage === 0
              ? itemsPerPage
              : calcEndPage * itemsPerPage + itemsPerPage
          }&offset=${
            (startPage - 1) * itemsPerPage
          }&municipio=${municipioSelected}
          &ponto=${selectedPonto == 0 ? "" : selectedPonto}
          &search=${searchedValue}&vinculadas=true&ordering=responsavel`
        );

        const parsedData = parseFamiliasDocument(results);
        const pasedDataCartao = parseFamiliasCartao(results);

        setListaFamiliasCartao(pasedDataCartao);
        setListaFamilias(parsedData);
        setDownloading(false);
      } catch (err) {
        notify(
          "error",
          "Ocorreu um erro ao obter a lista de famílias vinculadas para pdf"
        );
        setDownloading(false);
      }
    }
  };

  useEffect(() => {
    if (listaFamilias.length !== 0) {
      setDownloading(true);
      if (csvCheck === true) {
        createCSV();
      } else if (cartaoCheck === true) {
        if (listaFamiliasCartao == 0) {
          notify(
            "warning",
            "As famílias não estão em situação ''CONFIRMADA'' "
          );
          setDownloading(false);
        } else {
          createCartao();
        }
      } else createPDF();
    }
  }, [listaFamilias]);

  const createPDF = async () => {
    try {
      let pontoNome = "";
      let municipioNome = municipios?.find(
        (item) => item.id == municipioSelected
      )?.nome;
      if (selectedPonto > 0) {
        pontoNome = pontos?.find((item) => item.id == selectedPonto)?.nome;
      }
      const blob = await pdf(
        <DocumentPDF
          data={listaFamilias}
          count={total}
          totalMunicipio={totalMunicipio}
          municipio={municipioNome ? municipioNome : ""}
          pontoNome={pontoNome}
          selectedPonto={selectedPonto}
          pontos={pontosData}
          startPage={startPage}
          endPage={endPage}
        />
      ).toBlob();

      const today = new Date();
      const datenow =
        today.getDate() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getFullYear();
      
      FileSaver.saveAs(
        blob,
        `familias_vinculadas_${municipioNome}_${
          selectedPonto != "0" ? `${pontoNome}_` : ""
        }${datenow}.pdf`
      );
      setDownloading(false);
    } catch (err) {
      notify("error", "Ocorreu um erro ao gerar arquivo PDF");
      setDownloading(false);
    }
  };

  const createCartao = async () => {
    try {
      let pontoNome = "";
      let municipioNome = municipios?.find(
        (item) => item.id == municipioSelected
      )?.nome;

      if (selectedPonto > 0) {
        pontoNome = pontos?.find((item) => item.id == selectedPonto)?.nome;
      }

      let queryTablePaginada = `familias/cartoes/?municipio=${municipioSelected}&ponto=${selectedPontoCartao}&cod_familia=${
        selectedFamiliasFilter == "" ? "" : selectedFamiliasFilter
      }&search=${searchedValue}`;

      const response = await api.get(queryTablePaginada, {
        responseType: "blob",
      });

      window.open(URL.createObjectURL(response.data));

      const today = new Date();
      const datenow =
        today.getDate() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getFullYear();
      FileSaver.saveAs(response.data, `Cartao_${municipioNome}_${datenow}.pdf`);

      setDownloading(false);
      setCartaoCheck(false);
      setCodsFamilias([]);
    } catch (error) {
      notify("error", "Erro no Servidor, não foi possível gerar o arquivo PDF");
      setDownloading(false);
      setCartaoCheck(false);
      console.log(error);
    }
  };

  const createCSV = async () => {
    try {
      setDownloading(true);
      await json2csv(
        listaFamilias,
        (err, csv) => {
          const csvData = new Blob([csv || ""], {
            type: "text/csv;charset=utf-8;",
          });
          FileSaver.saveAs(csvData, "relatorio_familias_vinc.csv");
        },
        {
          excelBOM: true,
          delimiter: {
            field: ";",
          },
        }
      );
      setDownloading(false);
      setCsvCheck(false);
    } catch (err) {
      notify("error", "Ocorreu um erro ao gerar arquivo CSV");
      setDownloading(false);
      setCsvCheck(false);
    }
  };

  useEffect(() => {
    loadFamilias();
  }, [offset, searchedValue]);

  function parseFamiliasCartao(familias: any[]) {
    let tmp: any[] = [];
    if (selectedFamilias.length > 0) {
      for (var i2 = 0; i2 < familias.length; i2++) {
        for (var j = 0; j < selectedFamilias.length; j++) {
          if (
            familias[i2].situacao === "CONFIRMADA" &&
            familias[i2].id === selectedFamilias[j]
          ) {
            const object = {
              responsavel: familias[i2].responsavel.nome,
              cod_familia: familias[i2].cod_familia,
              renda: familias[i2].renda_percapita.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              }),
              tipo: familias[i2].tipo,
              tipo_leite: familias[i2].tipo_leite,
              localidade: familias[i2].endereco.localidade.nome,
              municipio: familias[i2].endereco.municipio.nome,
              ponto: familias[i2].ponto.nome,
              situacao: familias[i2].situacao,
            };
            tmp.push(object);
          }
        }
      }
    } else {
      for (var i = 0; i < familias.length; i++) {
        if (familias[i].situacao === "CONFIRMADA") {
          const object = {
            responsavel: familias[i].responsavel.nome,
            cod_familia: familias[i].cod_familia,
            renda: familias[i].renda_percapita.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            }),
            tipo: familias[i].tipo,
            tipo_leite: familias[i].tipo_leite,
            localidade: familias[i].endereco.localidade.nome,
            municipio: familias[i].endereco.municipio.nome,
            ponto: familias[i].ponto.nome,
            situacao: familias[i].situacao,
          };
          tmp.push(object);
        }
      }
    }
    return tmp;
  }

  function parseFamiliasDocument(familias: any[]) {
    let tmp: any[] = [];
    if (selectedPonto == 0) {
      let index = pontos.indexOf(
        pontos?.find((item) => item.nome == familias[0].ponto.nome)
      );
      pontos?.map((ponto) => {
        ponto.familias = [];
      });

      for (var i = 0; i < familias.length; i++) {
        if (familias[i].ponto.nome != pontos[index].nome) {
          index = pontos.indexOf(
            pontos?.find((item) => item.nome == familias[i].ponto.nome)
          );
        }
        const object = {
          responsavel: familias[i].responsavel.nome,
          cod_familia: familias[i].cod_familia,
          renda: familias[i].renda_percapita.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          }),
          tipo: familias[i].tipo,
          tipo_leite: familias[i].tipo_leite,
          localidade: familias[i].endereco.localidade.nome,
          municipio: familias[i].endereco.municipio.nome,
          ponto: familias[i].ponto.nome,
          situacao: familias[i].situacao,
        };
        pontos[index].familias.push(object);
        tmp.push(object);
      }
      setPontosData(pontos);
    } else {
      if (selectedFamilias.length > 0) {
        for (var i2 = 0; i2 < familias.length; i2++) {
          for (var j = 0; j < selectedFamilias.length; j++) {
            if (familias[i2].id === selectedFamilias[j]) {
              const object = {
                responsavel: familias[i2].responsavel.nome,
                cod_familia: familias[i2].cod_familia,
                renda: familias[i2].renda_percapita.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }),
                tipo: familias[i2].tipo,
                tipo_leite: familias[i2].tipo_leite,
                localidade: familias[i2].endereco.localidade.nome,
                municipio: familias[i2].endereco.municipio.nome,
                ponto: familias[i2].ponto.nome,
                situacao: familias[i2].situacao,
              };
              tmp.push(object);
            }
          }
        }
      } else {
        familias.map((fam: any) => {
          const object = {
            responsavel: fam.responsavel.nome,
            cod_familia: fam.cod_familia,
            renda: fam.renda_percapita.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            }),
            tipo: fam.tipo,
            tipo_leite: fam.tipo_leite,
            localidade: fam.endereco.localidade.nome,
            municipio: fam.endereco.municipio.nome,
            ponto: fam.ponto.nome,
            situacao: fam.situacao,
          };
          tmp.push(object);
        });
      }
    }
    return tmp;
  }

  //Paginação

  //Altera a página de acordo com o valor do botão escolhido do componente de paginação
  const changePage = useCallback((page: number) => {
    let newOffset = (page - 1) * itemsPerPage;
    setOffset(newOffset);
    setPage(page);
  }, []);

  //Avança uma página
  const next = useCallback(() => {
    let newOffset = offset + itemsPerPage;
    let newPage = page + 1;
    setOffset(newOffset);
    setPage(newPage);
  }, [offset, page]);

  //Volta uma página
  const previous = useCallback(() => {
    let newOffset = offset - itemsPerPage;
    let newPage = page - 1;
    setOffset(newOffset);
    setPage(newPage);
  }, [offset, page]);

  //Vai direto para a ultima página
  const last = useCallback(() => {
    setOffset(
      (total % itemsPerPage === 0
        ? Math.floor(total / itemsPerPage)
        : Math.floor(total / itemsPerPage) + 1) *
        itemsPerPage -
        itemsPerPage
    );
    setPage(
      total % itemsPerPage === 0
        ? Math.floor(total / itemsPerPage)
        : Math.floor(total / itemsPerPage) + 1
    );
  }, [total]);

  //Vai direto para a primeira página
  const first = useCallback(() => {
    setOffset(0);
    setPage(1);
  }, []);

  // Função de busca por texto
  const search = useCallback((searchedValue: string) => {
    setSearchedValue(searchedValue);
    setOffset(0);
    setPage(1);
  }, []);

  const detalhar = async (row: any) => {
    const { data } = await api.get(`familias/${row.id}/`);
    setFamiliaEditar(data);
    toggleDetalhar();
  };

  const confirmarDesvincular = (row: any) => {
    const { id, situacao } = row;
    if (situacao !== "EM ANÁLISE") {
      notify("error", "Somente é possível desvincular famílias em análise");
      return;
    }
    setAlertaTipo({
      acao: "desvincular",
      msg: "Deseja confirmar a desvinculação da família?",
    });
    setFamiliaId(id);
    toggleAlert();
  };

  const confirmarTrocaPonto = () => {
    if (!selectedPontoTroca) {
      notify("warning", "É necessário selecionar um ponto para a troca");
      return;
    }
    setAlertaTipo({
      acao: "trocar",
      msg: "Deseja confirmar a troca da familia de ponto?",
    });
    toggleAlert();
  };

  const desvincular = async () => {
    if (situacao) {
      try {
        // Modifica situação para em espera e torna ponto nulo na família
        const obj = {
          situacao: situacao,
          ponto: null,
        };
        await api.patch(`familias/${familiaId}/`, obj);
        notify("success", "Família desvinculada com sucesso");
        loadFamilias();
        loadPontos();
      } catch (err) {
        notify("error", "Ocorreu um erro ao desvincular a família");
      }
    }
  };

  const checarVagasPonto = (pontoTroca: number) => {
    const checkPonto = pontos.find((ponto) => ponto.id === pontoTroca);
    return checkPonto?.vagas > 0 ? false : true;
  };

  const trocarPonto = () => {
    if (checarVagasPonto(selectedPontoTroca.id)) {
      notify("error", "Esse ponto não possui mais vagas");
      return;
    }

    const vincular = async () => {
      try {
        await api.patch("familias/vincular_familias/", {
          ponto_id: selectedPontoTroca.id,
          familias: [familiaId],
        });
        notify("success", "Ponto trocado com sucesso");
        loadPontos();
        toggleTrocar();
        first();
      } catch (err) {
        notify("error", `Erro no servidor`);
      }
    };
    vincular();
  };

  const getFamiliaTrocar = (row: any) => {
    setFamiliaId(row.id);
    toggleTrocar();
  };

  const desvincularAcao = () => {
    desvincular();
    toggleAlert();
  };

  const trocarAcao = () => {
    trocarPonto();
    toggleAlert();
  };

  const toggleTrocar = () => {
    setSelectedPontoTroca(null);
    setModalTrocar(!modalTrocar);
  };

  const toggleDetalhar = () => {
    setModalDetalhar(!modalDetalhar);
  };

  const selectPonto = (obj: any) => {
    setSelectedPontoTroca(obj);
  };

  const toggleAlert = () => {
    setModalAlert(!modalAlert);
  };

  const togglePdf = () => {
    setModalPdf(!modalPdf);
  };

  const handleFileTypeChange = (fileType: string) => {
    setFileType(fileType);
    togglePdf();
  };

  const toggleCsv = () => {
    setCsvCheck(false);
  };

  const toggleCartao = () => {
    setCartaoCheck(false);
  };

  useEffect(() => {
    if (municipioSelected) {
      const municipiosFilter = municipios.filter(
        (municipio) => municipio.id === Number(municipioSelected)
      );
      if (municipiosFilter.length) {
        setSelectMunicipioName(municipiosFilter[0].nome);
      }
    }
  }, [municipioSelected]);

  return (
    <Layout>
      <MainContainer
        titlePage="Lista de famílias vinculadas por ponto"
        iconPage={<FiUsers />}
      >
        <AlertModal
          isOpen={modalAlert}
          toggle={toggleAlert}
          className="modal-md"
          tipo={alertaTipo}
          desvincular={desvincularAcao}
          trocar={trocarAcao}
        />
        <PdfModal
          fileType={fileType}
          isOpen={modalPdf}
          toggle={togglePdf}
          toggleCsv={toggleCsv}
          csvCheck={csvCheck}
          countCards={codsFamilias.length}
          className="modal-md"
          getFamilias={(startPage: number, endPage: number) =>
            getListaFamilias(startPage, endPage)
          }
          endPageDefault={Math.ceil(total / itemsPerPage)}
        />
        {modalDetalhar ? (
          <DetalharModal
            isOpen={modalDetalhar}
            toggle={toggleDetalhar}
            familiaSelecionada={familiaEditar}
          />
        ) : (
          ""
        )}
        {modalTrocar ? (
          <TrocarModal
            pontos={pontosFiltrado}
            trocarPonto={confirmarTrocaPonto}
            isOpen={modalTrocar}
            toggle={toggleTrocar}
            selectPonto={selectPonto}
            municipio={selectMunicipioName}
            total={totalPontos}
            className="modal-lg"
          />
        ) : (
          ""
        )}
        <Container>
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between">
              <div>
                <h6>Filtro de pesquisa</h6>
              </div>
              {isSearching}
            </div>
            <div className="card-body">
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="form filterForm"
              >
                <div className="form-row">
                  <div className="form-group col">
                    <label>Município</label>
                    <CustomInput>
                      <select
                        name="municipiosSelect"
                        className={
                          errors.municipiosSelect
                            ? "CustomInput error-input"
                            : "CustomInput"
                        }
                        placeholder="Selecione um município"
                        ref={register({
                          required: true,
                          validate: (value) => value > 0,
                        })}
                        onChange={(e: any) => {
                          setMunicipioSelected(e.currentTarget.value);
                          setValue("pontoSelect", 0);
                          setSelectedPonto("0");
                          first();
                        }}
                        style={{ background: "white" }}
                      >
                        <option value={0}>Selecione um Município</option>
                        {municipios.map((item) => (
                          <option value={item.id}>{item.nome}</option>
                        ))}
                      </select>
                      {errors.municipiosSelect?.type === "required" && (
                        <span>Campo obrigatório</span>
                      )}
                      {errors.municipiosSelect?.type === "validate" && (
                        <span>Campo obrigatório</span>
                      )}
                    </CustomInput>
                  </div>

                  {municipioSelected != 0 && isPontosLoading ? (
                    <div className="form-group col">
                      <CustomInput
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          margin: 25,
                        }}
                      >
                        <CustomSpinner2 />
                      </CustomInput>
                    </div>
                  ) : (
                    <div className="form-group col">
                      <label>Ponto</label>
                      <CustomInput>
                        <select
                          name="pontoSelect"
                          className={
                            errors.pontoSelect
                              ? "CustomInput error-input"
                              : "CustomInput"
                          }
                          ref={register({
                            required: true,
                            validate: (value) => pontos.length > 0,
                          })}
                          value={selectedPonto.toLocaleString()}
                          onChange={(e: any) => {
                            setSelectedPonto(e.currentTarget.value);
                            first();
                          }}
                          style={{ background: "white" }}
                        >
                          {pontos.length === 0 ? (
                            <option value={0}>Selecione um Ponto</option>
                          ) : (
                            <option value={0}>Todos</option>
                          )}

                          {pontos.map((item) => (
                            <option value={item.id}>{item.nome}</option>
                          ))}
                        </select>
                        {errors.pontoSelect?.type === "validate" && (
                          <span>Campo obrigatório</span>
                        )}
                      </CustomInput>
                    </div>
                  )}
                </div>
                <div className="d-flex justify-content-end">
                  <button
                    type="submit"
                    className="btn btn-sm btn-success"
                    disabled={isSearching ? true : false}
                  >
                    Pesquisar
                  </button>
                </div>
              </form>
            </div>
          </div>

          {familia.length > 0 && (
            <div className="local-search mt-4">
              <SearchBar
                placeholder="Responsável ou código familiar"
                submitHandler={search}
              />
            </div>
          )}

          {selectedPonto > 0 ? (
            <>
              <Table
                fields={fields}
                rows={familia}
                hasSelection={true}
                hasSelectionAll={false}
                isMultiple={true}
                changeSelected={changeSelected}
                removeAll={removeAll}
                clear={isFamiliasCleared}
                resetClear={resetClear}
                functions={[
                  { name: "Visualizar", action: detalhar },
                  {
                    iconBtn: <CustomIconDesvincular />,
                    name: "Desvincular",
                    action: confirmarDesvincular,
                  },
                  {
                    iconBtn: <CustomIconTrocarPonto />,
                    name: "Trocar ponto",
                    action: getFamiliaTrocar,
                  },
                ]}
              />
            </>
          ) : (
            <>
              <Table
                fields={fields}
                rows={familia}
                hasSelection={false}
                hasSelectionAll={false}
                changeSelected={changeSelected}
                clear={isFamiliasCleared}
                functions={[
                  { name: "Visualizar", action: detalhar },
                  {
                    iconBtn: <CustomIconDesvincular />,
                    name: "Desvincular",
                    action: confirmarDesvincular,
                  },
                  {
                    iconBtn: <CustomIconTrocarPonto />,
                    name: "Trocar ponto",
                    action: getFamiliaTrocar,
                  },
                ]}
              />
            </>
          )}

          <div className="botoes">
            <Button
              name={`Desfazer seleção
								${selectedFamilias.length > 0 ? `(${selectedFamilias.length})` : ""}`}
              iconButtom={<AiOutlineClose />}
              color="grey"
              onClick={clearFamilias}
            />
            <Button
              onClick={() => {
                if (familia.length === 0) {
                  return;
                } else if (!downloading) {
                  handleFileTypeChange('PDF');
                }
              }}
              name={"Baixar PDF"}
              color={downloading || familia.length === 0 ? "grey" : "blue"}
              iconButtom={<MdPictureAsPdf />}
            />

            <Button
              onClick={() => {
                if (familia.length === 0) {
                  return;
                } else if (!downloading) {
                  setCsvCheck(true);
                  handleFileTypeChange('CSV');
                }
              }}
              name={"Baixar CSV"}
              color={downloading || familia.length === 0 ? "grey" : "blue"}
              iconButtom={<FaFileCsv />}
            />

            {selectedPontoCartao > 0 ? (
              <Button
                onClick={() => {
                  if (familia.length === 0) {
                    return;
                  } else if (!downloading) {
                    setCartaoCheck(true);
                    handleFileTypeChange('Cartão');
                  }
                }}
                name={"Baixar Cartão"}
                color={downloading || familia.length === 0 ? "grey" : "blue"}
                iconButtom={<MdPictureAsPdf />}
              />
            ) : (
              <></>
            )}

            {downloading && <CustomSpinner2 />}
          </div>
          <div className="containerFooterTable">
            Visualizando {familia.length} de um total de {total} registros
            <div className=""></div>
            <Pagination
              itemCount={total}
              itemsPerPage={itemsPerPage}
              selectedPage={page}
              handlePageChange={changePage}
              handleNextPage={next}
              handlePreviousPage={previous}
              handleLastPage={last}
              handleFirstPage={first}
              maxPages={5}
            />
          </div>
        </Container>
      </MainContainer>
    </Layout>
  );
};

export default FamiliasVinculadas;
