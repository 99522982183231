import React from "react";
import Modal from "../../../../template/components/Modal";
import PdfForm from "../PdfForm";

import {
  ButtonClose,
  ButtonSave,
  CustomAiOutlineCheck,
  CustomAiOutlineClose,
} from "../DetalharModal/styles";

interface IProps {
  isOpen: boolean;
  csvCheck: boolean;
  toggle: () => void;
  countCards?: number;
  toggleCsv: () => void;
  className?: string;
  getFamilias: (start: number, end: number) => void;
  endPageDefault: number;
  fileType: string;
}

const PdfModal = ({
  isOpen,
  toggle,
  toggleCsv,
  csvCheck,
  className,
  countCards,
  getFamilias,
  endPageDefault,
  fileType,
}: IProps) => {
  const footerButtons = () => {
    return (
      <React.Fragment>
        <ButtonClose
          onClick={() => {
            toggle();
            toggleCsv();
          }}
        >
          <CustomAiOutlineClose /> Cancelar
        </ButtonClose>
        <ButtonSave type="submit" form="pdfForm">
          <CustomAiOutlineCheck />
          {"Confirmar geração"}
        </ButtonSave>
      </React.Fragment>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      fixed={false}
      modalTitle={csvCheck ? "Gerar CSV com lista" : "Gerar PDF com lista"}
      footerContent={footerButtons()}
      className={className}
    >
      <div className="mt-2">
        <PdfForm
          fileType={fileType}
          endPageDefault={endPageDefault}
          countCards={countCards}
          getFamilias={(startPage: number, endPage: number) =>
            getFamilias(startPage, endPage)
          }
        />
      </div>
    </Modal>
  );
};

export default PdfModal;
