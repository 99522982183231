import React, { useCallback, useEffect, useState } from "react";
import { FormContainer } from "./styles";
import { useForm } from "react-hook-form";
import { dialogBox } from "../../../../template/utils/dialogBox";

interface IForm {
  startPage: number;
  endPage: number;
}

interface IProps {
  getFamilias: (startPage: number, endPage: number) => void;
  endPageDefault: number;
  countCards?: number;
  fileType: string;
}

const PdfForm = ({ getFamilias, endPageDefault, countCards, fileType }: IProps) => {
  const [quantCard, setQuantCard] = useState<string>("");

  useEffect(() => {
    if (countCards == 0) {
      setQuantCard("todos");
    } else {
      setQuantCard(countCards?.toString() || "");
    }
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IForm>();

  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );

  const submit = ({ startPage, endPage }: IForm) => {
    if (Number(startPage) > Number(endPage)) {
      notify(
        "error",
        "O valor da paginação inicial tem que ser menor ou igual ao da paginação final"
      );
      return;
    }

    getFamilias(startPage, endPage);
  };

  return (
    <>
      <FormContainer id="pdfForm" onSubmit={handleSubmit(submit)}>
        <div className="row">
          <div className="inputsGroup col-6">
            <label>
              {fileType === "PDF"
                ? "Deseja gerar lista de famílias vinculadas?" 
                : fileType === "CSV" 
                ? "Deseja gerar tabela de famílias vinculadas?"
                : `Deseja gerar ${quantCard} cartões?`}
            </label>

            <input
              className={`form-control ${
                errors.startPage ? "error-input" : ""
              }`}
              type="hidden"
              name="startPage"
              ref={register({ required: true, min: 1 })}
              defaultValue={1}
            />
            {errors.startPage?.type === "required" && (
              <span className="error-span">Campo obrigatório</span>
            )}
            {errors.startPage?.type === "min" && (
              <span className="error-span">Informe um valor maior que 0</span>
            )}
          </div>
          <div className="inputsGroup col-6">
            <input
              className={`form-control ${errors.endPage ? "error-input" : ""}`}
              type="hidden"
              name="endPage"
              ref={register({ required: true, min: 1 })}
              defaultValue={endPageDefault}
            />
            {errors.endPage?.type === "required" && (
              <span className="error-span">Campo obrigatório</span>
            )}
            {errors.endPage?.type === "min" && (
              <span className="error-span">Informe um valor maior que 0</span>
            )}
          </div>
        </div>
      </FormContainer>
    </>
  );
};

export default PdfForm;
